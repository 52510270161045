import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "./components/components.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { AdminrootLayoutComponent } from './layouts/adminroot-layout/adminroot-layout.component';

import { AppRoutes } from "./app.routing";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AccessTokenInterceptor } from "./interceptors/accessToken.interceptor";
import { LoginComponent } from './pages/auth/login/login.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { ReactiveFormsModule } from "@angular/forms";
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { KwLayoutComponent } from "./kw/kw-layout/kw-layout.component";

@NgModule({
  declarations: [
      AppComponent,
      AdminLayoutComponent, 
      AuthLayoutComponent, 
      AdminrootLayoutComponent, 
      LoginComponent,
      RegisterComponent,
      ResetPasswordComponent,
      KwLayoutComponent
    ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: true
    }),
    NgbModule,
    ToastrModule.forRoot(), // ToastrModule added
    ComponentsModule,
    NgxSpinnerModule,
    DragDropModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { Routes } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { AdminrootLayoutComponent } from "./layouts/adminroot-layout/adminroot-layout.component";
import { AuthGuard } from "./guards/auth.guard";
import { LoginComponent } from "./pages/auth/login/login.component";
import { ResetPasswordComponent } from "./pages/auth/reset-password/reset-password.component";
import { KwLayoutComponent } from "./kw/kw-layout/kw-layout.component";
import { IsSellerGuard } from "./guards/is-seller.guard";

export const AppRoutes: Routes = [
 
  
  {
    path: 'iniciar-sesion',
    component: LoginComponent,
  },
  { path: '',
    redirectTo: '/iniciar-sesion',
    pathMatch: 'full'
  },
  { path: 'restablecer',
    component: ResetPasswordComponent,
  },
  {
    path: 'user',
    component: AdminLayoutComponent,
    canActivate: [ 
      AuthGuard, IsSellerGuard
    ],
    children: [
      // {
      //   path: 'dashboard',
      //   loadChildren: "./pages/dashboard/dashboard.module#DashboardModule"
      // },
      // {
      //   path: 'contactos',
      //   loadChildren: "./pages/leads/leads.module#LeadsModule"
      // },
      // {
      //   path: 'productos',
      //   loadChildren: "./pages/products/products.module#ProductsModule"
      // },
      // {
      //   path: 'shopping',
      //   loadChildren: "./pages/shopping/shopping.module#ShoppingModule"
      // },
      {
        path: 'ajustes',
        loadChildren: "./pages/user/user.module#UserModule"
      },
      {
        path: "productos",
        loadChildren: "./pages/forms/forms.module#FormsModules"
      },
      // {
      //   path: "tables",
      //   loadChildren: "./pages/tables/tables.module#TablesModule"
      // },
      // {
      //   path: "maps",
      //   loadChildren: "./pages/maps/maps.module#MapsModule"
      // },
      // {
      //   path: "widgets",
      //   loadChildren: "./pages/widgets/widgets.module#WidgetsModule"
      // },
      // {
      //   path: "charts",
      //   loadChildren: "./pages/charts/charts.module#ChartsModule"
      // },
      // {
      //   path: "calendar",
      //   loadChildren: "./pages/calendar/calendar.module#CalendarModulee"
      // },
      // {
      //   path: "",
      //   loadChildren:
      //     "./pages/pages/user-profile/user-profile.module#UserModule"
      // },
      // {
      //   path: "",
      //   loadChildren: "./pages/pages/timeline/timeline.module#TimelineModule"
      // }
    ]
  },
  {
    path: 'kw',
    component: KwLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './kw/kw.module#KwModule'
      }
    ]
  },
  {
    path: "",
    component: AdminrootLayoutComponent,
    canActivate: [ AuthGuard ],
    children: [
      {
        path: "admin",
        loadChildren: "./pages/admin/products-admin/products-admin.module#ProductsAdminModule"
      },
      {
        path: "admin",
        loadChildren: "./pages/admin/forms-admin/forms-admin.module#FormsAdminModule"
      }
    ] 
  },
  { path: '**', redirectTo: '/iniciar-sesion' },

];
